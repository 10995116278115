import { z } from 'zod'
import { zfd } from 'zod-form-data'
import { type MapFiltersType } from '~/atoms'

export type LocationFilter = {
  $geoWithin: {
    $geometry: {
      type: string
      coordinates: number[][][]
    }
  }
}

export const LocationFilterSchema = z.object({
  $geoWithin: z.object({
    $geometry: z.object({
      type: z.string(),
      coordinates: z.array(
        z.array(z.array(zfd.numeric(z.number()), zfd.numeric(z.number())))
      ),
    }),
  }),
})

export const MapFiltersSchema = z.object({
  lat: z.string().optional(),
  lng: z.string().optional(),
  z: z.string().optional(),
  b: z.string().optional(),
  p: z.string().optional(),
  search: z.string().optional(),
  types: z.array(z.string()).optional(),
  statuses: z.array(z.string()).optional(),
  propertyTypes: z.array(z.string()).optional(),
  propertySubtypes: z.array(z.string()).optional(),
  submarkets: z.array(z.string()).optional(),
  zoning: z.array(z.string()).optional(),
  prices: z.array(zfd.numeric(z.number().optional())).optional(),
  rates: z.array(zfd.numeric(z.number().optional())).optional(),
  bsf: z.array(zfd.numeric(z.number().optional())).optional(),
  sf: z.array(zfd.numeric(z.number().optional())).optional(),
  acres: z.array(zfd.numeric(z.number().optional())).optional(),
  companies: z.array(z.string()).optional(),
  brokers: z.array(z.string()).optional(),
  created: z.string().optional(),
  transacted: z.string().optional(),
  sort: z.string().optional(),
  postalCodes: z.array(z.string()).optional(),
  cities: z.array(z.string()).optional(),
  counties: z.array(z.string()).optional(),
  officeSf: z.array(zfd.numeric(z.number().optional())).optional(),
  officeCeilingHeight: z.array(zfd.numeric(z.number().optional())).optional(),
  dockNumber: z.array(zfd.numeric(z.number().optional())).optional(),
  driveInNumber: z.array(zfd.numeric(z.number().optional())).optional(),
  trailerParkingSpaces: z.array(zfd.numeric(z.number().optional())).optional(),
  ceilingHeight: z.array(zfd.numeric(z.number().optional())).optional(),
  clearHeight: z.array(zfd.numeric(z.number().optional())).optional(),
  eaveHeight: z.array(zfd.numeric(z.number().optional())).optional(),
  overheadCranes: z.array(zfd.numeric(z.number().optional())).optional(),
  railDoors: z.array(zfd.numeric(z.number().optional())).optional(),
  mezzanineSf: z.array(zfd.numeric(z.number().optional())).optional(),
  // mezzanineHvac: z.string().optional(),
  warehouseSf: z.array(zfd.numeric(z.number().optional())).optional(),
  // warehouseHvac: z.string().optional(),
  freightDockNumber: z.array(zfd.numeric(z.number().optional())).optional(),
  // yard: z.string().optional(),
  // yardFenced: z.string().optional(),
  // yardPaved: z.string().optional(),
}) satisfies z.ZodType<MapFiltersType>
